import { Box, Wrap, WrapItem, Heading, Icon, Spacer, SimpleGrid, Text, Card, CardBody } from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';
import KneeIcon from "../assets/icons/knee.svg"
import DiabetesIcon from "../assets/icons/diabetes.svg"
import FemaleIcon from "../assets/icons/female.svg";
import ThyroidIcon from "../assets/icons/thyroid.svg";
import JointsIcon from "../assets/icons/bone.svg";
import OrthoIcon from "../assets/icons/orthopedic.svg";
import WomenIcon from "../assets/icons/women.svg";
import MetabolicIcon from "../assets/icons/metabolic.svg";
import HormoneIcon from "../assets/icons/hormone.svg";
import { GiStomach, GiLiver } from "react-icons/gi";
import { useBreakpointValue } from '@chakra-ui/react';

import {
  FaHeartbeat,
  FaShieldAlt, // Auto-immune Disorders
  FaHeadSideCough, // Stress/Anxiety
  FaBaby, // Pre-natal, Post-natal
  FaBrain,
} from "react-icons/fa";

const Conditions = () => {

  const conditions = [
    ["Diabetes", DiabetesIcon],
    ["Joint/Back Pain", JointsIcon],
    ["Hypertension", FaHeartbeat,],
    ["Thyroid Disorder", ThyroidIcon],
    ["High Cholesterol/Lipids", GiLiver],
    ["Asthma", FaHeadSideCough],
    ["Arthritis", KneeIcon],
    ["Auto-immune Disorders", FaShieldAlt],
    ["Stress/Anxiety", FaBrain],
    ["Menstrual Disorders", FemaleIcon],
    ["Gastrointestinal Conditions", GiStomach],
    ["Pre-natal, Post-natal", FaBaby],
  ];

  const categories = [
    {
      title: 'Metabolic Disorder',
      icon: MetabolicIcon,
      conditions: 'Diabetes, High BP, High Cholesterol',
    },
    {
      title: 'Musculoskeletal Conditions',
      icon: OrthoIcon,
      conditions: 'Chronic Back Pain, Joint Pain, Arthritis',
    },
    {
      title: "Women's Health",
      icon: WomenIcon,
      conditions: 'Menstrual Health, PCOS, Pre/post-natal',
      icons: [FemaleIcon, FaBaby]
    },
    {
      title: 'Auto-immune Conditions',
      icon: HormoneIcon,
      conditions: 'Thyroid, Auto-immune Conditions',
    },
  ];

  const isMobile = useBreakpointValue({ base: true, sm: false });
  const IconComponent = ({ icon }) => {
    if (typeof icon === 'function') {
      return <Icon as={icon} boxSize={5} />;
    } else {
      return <ReactSVG src={icon} style={{ width: '20px', height: '20px' }} />
    }
  };

  const boxStyle = {
    padding: { base: 2, md: 3 },
    margin: { base: 2, md: 2 },
    backgroundColor: "white",
    shadow: "lg",
    display: "flex", // Enable Flexbox
    rounded: "md",
    width: "fit-content", // Key: Make Box width fit content
  };

  const containerStyle = {
    padding: { base: 2, xl: 4 },
    margin: { base: 3, xl: 4 },
    display: "flex",
    width: '100%',
    alignItems: "center",
  };

  return (
    <Box sx={containerStyle} >
    {!isMobile && 
        <Wrap spacing={{ base: 0, md: 2, lg: 3, xxl: 4 }} justify='center'
          width={{ base: "100%", xxl: "90%" }} mx="auto">
          {conditions.map(([condition, icon]) => (
            <WrapItem key={condition}>
              <Box sx={boxStyle}>
                <IconComponent icon={icon} />
                <Spacer width= {{base:"8px", xl:"10px", xxl:"12px" }}/>
                <Heading as='h5' fontWeight="500" textAlign={'left'} noOfLines={1} 
                  fontSize={"1em"}>
                  {condition}
                </Heading>
              </Box>
            </WrapItem>
          ))}
          </Wrap>
    }
    {isMobile && 
        <SimpleGrid columns={2} spacing={4} padding={1}>
          {categories.map((category, index) => (
            <Card key={'category-card-' + index} variant={'elevated'} align={'center'} shadow={'lg'} rounded={'2xl'}>
              <CardBody align='center' padding={2} m={1} > 
                <ReactSVG src={category.icon} style={{ width: '50px', height: '50px' }} />
                <Text fontSize="0.9em" noOfLines={3} textAlign={'center'} mt={1}> {category.conditions} </Text>
              </CardBody>
            </Card>
          ))}
        </SimpleGrid>
    }
    </Box>
  )
};

export default Conditions;
