import { Box, Heading, VStack, Text, HStack, Icon } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';

import FeedbackCarousel from './FeedbackCarousel';

const Feedback = () => {

  const containerStyle = {
    padding: { base: 2, xl: 4 },
    margin: { base: 3, xl: 4 },
    display: "flex",
    width: '100%',
    alignItems: "center",
  };

  return (
    <Box sx={containerStyle}>
      <VStack alignItems="center" mb="4" mt="2" p="2" justifyContent="center" >
        <Heading as='h3' size='lg' textAlign={'center'}> Client Feedback</Heading>
        <HStack spacing={1} mt={2} alignItems="center">
          <Text fontSize="1.4em" mr={2} mt={1} fontWeight='bold'> 4.9 </Text>
          {Array(5).fill('').map((_, index) => (
            <Icon key={index} as={StarIcon} color={'yellow.400'} boxSize={6} />
          ))}
        </HStack>
        <Text fontSize="1em" color={'gray.600'} mt={-2} mb={2}> (250+ ratings) </Text>
        <FeedbackCarousel />
      </VStack>
    </Box>
  );
};

export default Feedback;