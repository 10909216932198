import { Box, Flex, Image, Heading, Text, VStack, Divider, Card } from '@chakra-ui/react';
import UmeshTherapistImage from '../assets/images/UmeshTherapist.jpg';
import { useBreakpointValue } from '@chakra-ui/react';

const TherapistProfile = () => {

  const desktopTherapists = [
    {
      name: 'Yogi Vijnanaprakash',
      qualification: 'MSc. Yoga Therapy, Grand Master of Transformational Yoga & Meditation',
      experience: '20+ years of experience',
      profile:
        'Yogi Vijnanaprakash (Umesh Lalit) is dedicated to empowering individuals to lead a healthy life through therapeutic yoga practices. His extensive experience, coupled with formal qualifications from SVYASA and mastery of advance meditation techniques enables him to guide clients in addressing the root cause of various chronic conditions and achieving physical, mental, and spiritual well-being.',
      image: UmeshTherapistImage,
    },
  ];

  const mobileTherapists = [
    {
      name: 'Yogi Vijnanaprakash',
      qualification: 'MSc. Yoga Therapy, Grand Master in Meditation',
      experience: '20+ yrs experience',
      profile: 'Yogi Vijnanaprakash (Umesh Lalit) blends his academic training of yoga therapy from SVYASA and deep-seated meditation expertise to empower individuals to holistically address the root cause of their conditions and lead a healthy life.',
      image: UmeshTherapistImage,
    },
  ];

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const therapists = isMobile ? mobileTherapists : desktopTherapists;

  return (
    <Box display="flex" justifyContent="center" alignItems="center" padding="4"  mx="auto">
      <VStack alignItems="center" mt="4" mb="6">
      <Heading as='h3' size='lg' textAlign={'center'} mb={2}> Our Lead Therapist </Heading>
      <Flex direction="column" width={{ sm: "100%", md: "90%" }} align="center">
        {therapists.map((therapist, index) => (
          <Card key={index} p={8} maxW="95%" boxShadow="md" alignItems="center" my={4} bgColor={'#ebfcff'}>
            <Flex align="center" direction={{ base: 'column', md: 'row' }}>
              <Box mr={{ md: 6, lg:10 }} mb={{ base: 4, md: 0 }} position="relative">
                <Box
                  width={{ base: '150px', md: '180px' }}
                  height={{ base: '150px', md: '180px' }}
                  borderRadius="full"
                  overflow="hidden"
                >
                  <Image src={therapist.image} alt={therapist.name} objectFit="contain" />
                </Box>
              </Box>
              <VStack align="start" spacing={2} pl={4}>
                <Heading as="h5" size="md">
                  {therapist.name}
                </Heading>
                <Text fontWeight="semibold">{therapist.qualification}</Text>
                <Text fontWeight="semibold">{therapist.experience}</Text>
                <Divider/>
                <Text>{therapist.profile}</Text>
              </VStack>
            </Flex>
          </Card>
        ))}
      </Flex>
      </VStack>
    </Box>
  );
}

export default TherapistProfile;