import { Box, Button, HStack, Image, Link } from '@chakra-ui/react';
import ArogicLogo from '../assets/logo192.png';
import { logEvent } from '../analytics';
import RefLink from './RefLink';
import { useSignupUrl } from './UseSignupUrl';

const Header = () => {
  const getSignupUrl = useSignupUrl();
  const signupUrl = getSignupUrl();

  const handleSignUpClick = () => {
    logEvent('Button Click', 'Go to App', 'Go to App - Header');
  }
  return (
      <Box justifyContent="space-between" padding="4" spacing="4" width="100%" flexDirection={'row'} position="relative" top="0" zIndex="10">
        <HStack justifyContent="space-between">
          <HStack spacing={4} justifyContent="right">
            <HStack spacing={1} justifyContent="left">
              <RefLink to="/">
                <Image src={ArogicLogo} alt="Arogic" width="2em" height="auto" />
              </RefLink>
            </HStack>
          <RefLink to="/" style={{ color:'#ebfcff'}}> Home </RefLink>
          <RefLink to="/Research" style={{ color: '#ebfcff' }}> Research </RefLink>
          </HStack>
          <HStack spacing={2} justifyContent={"left"}>
            <Link href={signupUrl}>       
              <Button variant={'link'} color={'brand.100'} mr={4} onClick={handleSignUpClick}> 
                Go to App 
              </Button>
            </Link>
          </HStack>
        </HStack>
      </Box>
  )
};

export default Header;
