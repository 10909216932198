import { Box, SimpleGrid, VStack, Text, Heading } from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/react';

const Approach = () => {

  const isMobile = useBreakpointValue({ base: true, sm: false });

  const boxStyle = {
    padding: { base: 1, md: 3, lg: 3 },
    margin: { base: 2, md: 3, lg: 4 },
    backgroundColor: "white",
    alignItems: "center",
    rounded: { base: "xl", md: "2xl", lg:"3xl" },
    shadow: "md"
  };

  const textStyle = {
    padding: { base: 2, xxl: 3 },
    marginTop: { base: 1, lg: 1},
    textAlign: "center",
    fontSize: {base: '0.9em', md:'1em'},
  };

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" padding="4" width={{ sm:"100%", md:"90%", lg:"80%", xxl:"70%"}} mx="auto">
        <VStack alignItems="center" mt="4" mb="4" maxWidth={"95%"}>
          <Heading as='h3' size='lg'>Our Approach</Heading>
          {!isMobile && <Text m={2} textAlign={'center'} fontSize={'1.1em'} fontWeight={'500'} color={'teal.800'}>
            At Arogic, we combine the traditional wisdom of yoga with modern science and technology to empower you to live a healthier, more fulfilling life.
            Our therapy programs are tailored to meet your specific needs and offer:
          </Text> }
          {isMobile && <Text m={2} textAlign={'center'} fontSize={'1em'} fontWeight={'500'} color={'teal.800'}>
            At Arogic, we combine the traditional wisdom of yoga with modern science and technology to empower you to live a healthier, more fulfilling life. Our programs offer:
          </Text>}

          <SimpleGrid columns={{base:1, md:2}} mx="auto" width={{base:"100%", md: "95%", lg:"90%", xxl:"90%"}}>
            <Box sx={boxStyle}> 
              <Heading as='h6' fontSize={{base:'1em', lg:'1.1em'}} textAlign={'center'} mt='3'> Expert Guidance </Heading>
              <Text sx={textStyle}>
                Learn scientific techniques from Certified Therapists and receive targeted feedback
              </Text>
            </Box>
            <Box sx={boxStyle}> 
              <Heading as='h6' fontSize={{ base: '1em', lg: '1.1em' }} textAlign={'center'} mt='3'> Personalized Therapy </Heading>
              <Text sx={textStyle}>
                Maximize benefits with sessions customized to your condition and one-on-one support              
              </Text>
            </Box>
            <Box sx={boxStyle}>
              <Heading as='h6' fontSize={{ base: '1em', lg: '1.1em' }} textAlign={'center'} mt='3'> Holistic Wellness </Heading>
              <Text sx={textStyle}>
                Develop and integrate healthy lifestyle habits for physical and emotional well-being              </Text>
            </Box>
            <Box sx={boxStyle}>
              <Heading as='h6' fontSize={{ base: '1em', lg: '1.1em' }} textAlign={'center'} mt='3'> Exceptional Value </Heading>
              <Text sx={textStyle}>
                Get affordable personalized care, powered by our innovative technology. 
              </Text>
            </Box>
          </SimpleGrid>
        </VStack>
      </Box>
    </>
  )
};

export default Approach;

