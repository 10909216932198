import { Box, Heading, VStack, Link, Button } from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/react';
import { useSignupUrl } from './UseSignupUrl';
import { logEvent } from '../analytics';

const CTA = () => {
  
  const isMobile = useBreakpointValue({ base: true, md: false });
  const getSignupUrl = useSignupUrl();
  const signupUrl = getSignupUrl();

  const handleSignUpClick = () => {
    logEvent('Button Click', 'Sign Up Now', 'Sign up now - Footer');
  };

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" padding="4" margin={2} width="100%" mb={4} mt={8}>
        <VStack>
          {isMobile &&
            <Heading as="h4" size={'md'} mb={4} fontWeight={'600'} textAlign={'center'}>
              Take control of your health
            </Heading>
          }
          {!isMobile && 
            <Heading as="h4" size={'md'} mb={4} fontWeight={'600'} textAlign={'center'}>
              Take control of your health and feel the difference
            </Heading>
          }
          <Link href={ signupUrl } width="100%" textAlign={'center'}>
            <Button size='lg' colorScheme='blue' bgColor='#0e4f62' color={'white'} onClick={handleSignUpClick}>
              Sign up now
            </Button>
          </Link>        
        </VStack>
      </Box>
    </>
  )
};

export default CTA;

