import { logEvent } from './analytics'; // Import logEvent

const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(sendToGA);
      getFID(sendToGA);
      getFCP(sendToGA);
      getLCP(sendToGA);
      getTTFB(sendToGA);
    });
  }
};

function sendToGA({ name, value, id }) {
  logEvent('Website Vitals', name, `${name}: ${value}`); // Send event to GA4
}

export default reportWebVitals;