import ReactGA from 'react-ga4';

const MEASUREMENT_ID = 'G-SK79FBQQ3G';

const initGA = () => {
  ReactGA.initialize(MEASUREMENT_ID);
};

const logPageView = (pagePath) => {
  const path = 'web' + pagePath;
  ReactGA.send({ hitType: 'pageview', page: path });
};

const logEvent = (category = '', action = '', label = '') => {
  if (category && action) {
    ReactGA.event({ category, action, label });
  }
};

export { initGA, logPageView, logEvent };