import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage.js';
import PrivacyPolicy from './pages/PrivacyPolicy.js';
import TermsOfUse from './pages/TermsOfUse.js';
import Research from './pages/Research.js';
import { initGA, logPageView } from './analytics';


const App = () => {
  const location = useLocation();

  useEffect(() => {
    initGA(); // Initialize Google Analytics
  }, []);

  useEffect(() => {
    logPageView(location.pathname); // Log page view on route change
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/Home" element={<HomePage />}/>
      <Route path="/Policy" element={<PrivacyPolicy />} />
      <Route path="/Terms" element={<TermsOfUse />} />
      <Route path="/Research" element={<Research />} />
      {/* Other routes */}
    </Routes>
  );
}

const AppWrapper = () => {
    return (
    <Router forceRefresh={false}>
      <App />
    </Router>
  );
}

export default AppWrapper;
