import { Box, HStack, Divider, Link, Image, VStack, Text } from '@chakra-ui/react';
import ArogicLogo from '../assets/logo192.png';
import { useBreakpointValue } from '@chakra-ui/react';
import RefLink from './RefLink';

const Footer = () => {

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <>
    { !isMobile &&
      <Box alignItems="center" paddingY="4" paddingX="4" width="100%" mb={10}>
        <Divider borderColor={'gray.300'} width={'90%'} mb={6} mx="auto" />
        <VStack spacing={1} alignItems="center" justifyContent={'space-between'}>
          <HStack justifyContent={'space-around'} alignContent="center" width="90%" >

            <VStack spacing={1} alignItems="left">
              <Text fontWeight={'700'} color={'teal.800'}> Contact: </Text>
              <Link href='whatsapp://send?phone=+919346368945' isExternal color={'teal.800'}>
                +91-93463 68945
              </Link>
              <Link href='mailto:support@arogic.com' isExternal color={'teal.800'}>
                support@arogic.com
              </Link>
            </VStack>
            
            <VStack spacing={1} alignItems="center" mr={10}>
                <RefLink to="/">
                  <Image src={ArogicLogo} alt="Arogic" width="64px" height="auto" />
                </RefLink>
              <Text> © {new Date().getFullYear()} Arogic Wellness Pvt Ltd. All rights reserved. </Text>
            </VStack>

            <VStack spacing={1} alignItems="left">
              <Text> &nbsp; </Text>
              <RefLink to='/Policy' color={'teal.800'}> Privacy Policy </RefLink> 
              <RefLink to='/Terms' color={'teal.800'}> Terms of Use </RefLink>
            </VStack>

          </HStack>
        </VStack>
      </Box>
    }
    { isMobile &&
        <Box justifyContent="space-between" paddingY="4" paddingX="4" width="100%" mb={6}>
          <Divider borderColor={'gray.300'} width={'90%'} mb={6} mx="auto" />
          <VStack spacing={1} alignItems="center" justifyContent={'space-between'}>
            <HStack justifyContent={'space-between'} alignContent="center" width="90%" >

              <VStack spacing={1} alignItems="left">
                <Text fontWeight={'700'} color={'teal.800'}> Contact: </Text>
                <Link href='whatsapp://send?phone=+919346368945' isExternal color={'teal.800'}>
                  +91-93463 68945
                </Link>
                <Link href='mailto:support@arogic.com' isExternal color={'teal.800'}>
                  support@arogic.com
                </Link>
              </VStack>

              <VStack spacing={1} alignItems="left">
                <Text> &nbsp; </Text>
                <RefLink to='/Policy' color={'teal.800'}> Privacy Policy </RefLink>
                <RefLink to='/Terms' color={'teal.800'}> Terms of Use </RefLink>
              </VStack>

            </HStack>
            <VStack spacing={1} alignItems="center" textAlign={"center"} mt={6}>
              <RefLink to="/">
                <Image src={ArogicLogo} alt="Arogic" width="64px" height="auto" />
              </RefLink>
              <Text> © {new Date().getFullYear()} Arogic Wellness Pvt Ltd. </Text>
              <Text> All rights reserved. </Text>
            </VStack>
          </VStack>
        </Box>    }
    </>
  )
};

export default Footer;

