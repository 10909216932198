import { Box, Card, Heading, Icon, Link, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react'
import { useBreakpointValue } from '@chakra-ui/react';
import Footer from '../components/Footer.js';
import Header from '../components/Header.js';
import { FaExternalLinkAlt } from "react-icons/fa";



const Research = () => {

  const isMobile = useBreakpointValue({ base: true, md: false });

  const categories = [
    {
      title: 'Metabolic Disorders',
      summary: 'Cardio-Metabolic Disorders represent a cluster of interconnected health conditions that significantly impact the body\'s metabolism and cardiovascular system.These disorders are often linked with factors such as insulin resistance, chronic inflammation, poor lifestyle and chronic stress.Obesity often plays a central role, contributing to conditions like Fatty Liver and Metabolic Syndrome(where high blood pressure, blood sugar, and unhealthy cholesterol levels converge).These imbalances increase the risk of serious complications affecting the heart, vessels, liver and other organs.',
      therapyIntro: 'Yoga therapy provides a holistic strategy to address these interconnected disorders.',
      therapy: [
        { title: "Improves Insulin Sensitivity", description: "Targeted practices enhance insulin sensitivity, facilitating better glucose metabolism and blood sugar control." },
        { title: "Reduces Chronic Inflammation", description: "Stress-reducing effects combined with certain postures, helps lower chronic low-grade inflammation, a common factor for these disorders." },
        { title: "Manages Stress and Improves sleep", description: "Breathing exercises (pranayama) and meditation calm the nervous system and reduces stress hormones like cortisol, contributing to better sleep." },
        { title: "Improves Digestive Function", description: "Gentle Twists, bends and breathing techniques in yoga improve digestion and waste elimination, relevant for GI conditions and most metabolic disorders." },
        { title: "Enhances circulation", description: "Yoga practices improve blood circulation, lowering blood pressure and improving cardiovascular health." },
        { title: "Supports Hormonal Balance", description: "Contributing to regulation of the Basal Metabolic Rate (BMR), the rate at which body burns calories at rest." },
        { title: "Reduces Visceral Fat", description: "Certain practices target abdominal fat, which is strongly linked to metabolic disorders." },
        { title: "Weight management", description: "By promoting mindful eating, lifestyle modifications, and increased physical activity." }
      ],
      conditions: [
        { 
          title: 'Diabetes', 
          description: 'Diabetes mellitus is characterized by persistently elevated blood glucose levels. It occurs due to either insufficient insulin production (Type 1 diabetes, an auto- immune condition) or the body\'s inability to effectively use the insulin it produces (Type 2 diabetes). Type 2 diabetes is the most common and is strongly linked to lifestyle factors such as obesity, poor diet, and physical inactivity. Yoga therapy effectively addresses the underlying factors as evident in below studies:', 
          researchLinks: [
            { 
              title: "Effectiveness of a Yoga-Based Lifestyle Protocol (YLP) in Preventing Diabetes in a High-Risk Indian Cohort", 
              link: 'https://pmc.ncbi.nlm.nih.gov/articles/PMC8231281/',
              description: "This study involved 3380 individuals with pre-diabetes in India and found that a yoga program significantly reduced their risk of developing type 2 diabetes by about 64% over 3 months, while also increasing the likelihood of their blood sugar returning to normal."
            },
            {
              title: "Effects of yoga in adults with type 2 diabetes mellitus: A meta‐analysis",
              link: 'https://pmc.ncbi.nlm.nih.gov/articles/PMC5334310/',
              description: "This analysis combined the findings from 12 studies with 864 adults with type 2 diabetes. The results showed that yoga significantly lowers both fasting blood sugar by 23.7 mg/dl and long-term blood sugar (HbA1c)."
            },
            { 
              title: "The effects of yoga among adults with type 2 diabetes: A systematic review and meta-analysis", 
              link: 'https://pmc.ncbi.nlm.nih.gov/articles/PMC5653446/',
              description: "This review analyzed 23 studies with 2473 adults with  type 2 diabetes. It concluded that yoga practice leads to significant improvements in blood sugar control, including lower long-term blood sugar and fasting blood glucose with improvements quality of life." 
            },
          ] 
        },
        { 
          title: 'High Blood Pressure', 
          description: 'Hypertension, commonly known as high blood pressure is a prevalent health condition where the force of blood pushing against the artery walls is consistently too high. While genetics and certain medical conditions can contribute, lifestyle factors such as stress, poor diet, obesity and lack of physical activity play a significant role. Yoga helps address these effectively with stress reduction, improved circulation and promoting cardiovascular health.', 
          researchLinks: [
            {
              title: "Effectiveness of Yoga for Hypertension: Systematic Review and Meta-Analysis",
              link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC3679769/",
              description: "This meta-analysis reviewed 17 controlled studies involving 1013 participants across 4 countries including India with prehypertension or hypertension. Yoga had a positive effect in reducing blood pressure, with sub-analysis showing significant reductions (SBP: -8.17 mmHg, DBP: -6.14 mmHg) with incorporation specific postures,  meditation and breathing techniques."
            },
            {
              title: "The Effect of Yoga on Cardiovascular Disease Risk Factors: A Meta-Analysis",
              link: "https://pubmed.ncbi.nlm.nih.gov/36681213/ ",
              description: "This meta-analysis, published in 2023 analysed the effect across 64 randomised controlled trials with a total of 16,797 participants. It found yoga therapy to have a holistic positive effect that includes reduction in blood pressure, improvement in BMI and lipid profile."
            },
            {
              title: "Yoga as Antihypertensive Lifestyle Therapy: A Systematic Review and Meta-analysis",
              link: "https://www.researchgate.net/publication/331171648_Yoga_as_Antihypertensive_Lifestyle_Therapy_A_Systematic_Review_and_Meta-analysis",
              description: "This meta-analysis included a large sample size of 3517 participants with varying blood pressure status. Yoga led to moderate reductions in systolic BP of -5.0 mmHg and diastolic BP of -3.9 mmHg. Greater benefits were observed with yoga practiced 3 times a week, including breathing and relaxation techniques"
            }
          ] 
        },
        { 
          title: 'High Cholesterol/Lipids', 
          description: 'Dyslipidemia, or high cholesterol/lipids, is a condition marked by abnormal levels of fats in the blood, including elevated LDL cholesterol and triglycerides, and/or reduced HDL cholesterol. These imbalances contribute to plaque buildup in arteries, increasing the risk of cardiovascular disease like heart attack and stroke. Lifestyle factors like a high-fat diet, inactivity, obesity, and stress are significant contributors, and holistic yoga therapy effectively addresses these factors through a comprehensive approach.', 
          researchLinks: [
            {
              title: "The Effect of Yoga on the Lipid Profile: A Systematic Review and Meta-Analysis of Randomized Clinical Trials (2022)",
              link: "https://www.frontiersin.org/journals/nutrition/articles/10.3389/fnut.2022.942702/full",
              description: "This meta-analysis reviewed data from multiple RCTs covering 13,191 participants. The analysis showed a significant reduction in total cholesterol by -10.31 mg/dl and LDL cholesterol by -8.64 mg/dl along with increase in HDL cholesterol."
            },
            {
              title: "Effects of Static Meditation Practice on Blood Lipid Levels: A Systematic Review and Meta-Analysis (2024)",
              link: "https://www.mdpi.com/2227-9032/12/6/655",
              description: "This meta-analysis included multiple studies with healthy or sub-healthy adults. The analysis showed a significant reduction in triglyceride levels by -32.56 mg/dL and a significant reduction in total cholesterol levels by -13.91 mg/dL."
            },
            {
              title: "The effectiveness of yoga in modifying risk factors for cardiovascular disease and metabolic syndrome",
              link: "https://academic.oup.com/eurjpc/article-abstract/23/3/291/5926952?redirectedFrom=fulltext&login=false",
              description: "This meta-analysis included 32 Randomised controlled trials. Compared to non-exercise controls, yoga resulted in a significant improvement in total cholesterol by -18.48 mg/dl (95% CI -29.16 to -7.80) and triglycerides by -25.89 mg/dl (95% 1 CI -36.19 to -15.60)"
            }
          ] 
        },
        { 
          title: 'Gastrointestinal Conditions', 
          description: 'Gastrointestinal (GI) conditions encompass a wide range of disorders affecting the digestive system, from the esophagus to the intestines. Common GI conditions include irritable bowel syndrome (IBS), characterized by abdominal pain, bloating, and altered bowel habits; inflammatory bowel disease (IBD); ulcerative colitis, involving chronic inflammation of the digestive tract; and gastroesophageal reflux disease (GERD), marked by stomach acid reflux into the esophagus. These conditions are often influenced by factors such as stress, diet, and lifestyle. Yoga therapy, with its focus on stress reduction, mindful movement, and promoting relaxation, offers a holistic approach to improve digestive function, waste elimination and enhance overall well-being.',
          researchLinks: [
            {
              title: "A systematic review of yoga for the treatment of gastrointestinal disorders (Sept 2024)",
              link: "https://www.researchgate.net/publication/384078861_A_systematic_review_of_yoga_for_the_treatment_of_gastrointestinal_disorders",
              description: "This review analyzed 12 studies, including 7 randomized controlled trials focusing on Irritable Bowel Syndrome (IBS). The review concluded that most of studies demonstrated improvements in IBS symptom severity, mood-related symptoms, and quality of life in the yoga intervention groups compared to controls and yoga appears to be safe with potential across a spectrum of GI disorders."
            },
            {
              title: "Effect of Yoga in the Therapy of Irritable Bowel Syndrome: A Systematic Review",
              link: "https://pubmed.ncbi.nlm.nih.gov/27112106/",
              description: "This review analysed 6 randomized controlled trials (RCTs) covering 273 patients. The review concluded that yoga interventions demonstrated a beneficial effect over conventional treatment in decreasing bowel symptoms and IBS severity. Additionally, yoga showed significant improvements in quality of life, global improvement, and physical functioning compared to no treatment."
            }
          ]
        },
      ],
    },
    {
      title: 'Muscoskeletal Issues',
      summary: 'Musculoskeletal issues encompass a wide range of conditions affecting the body\'s movement, including muscles, bones, ligaments, tendons, and nerves.These conditions manifest as pain, stiffness, weakness, and reduced mobility, significantly impacting physical function and overall quality of life.The root causes are diverse, ranging from acute trauma like sprains and fractures to chronic wear and tear leading to degenerative conditions such as osteoarthritis, repetitive strain and overuse from work or sports, and autoimmune diseases like rheumatoid arthritis.Poor posture, excess weight, and sedentary lifestyles contribute further to these disorders.',
      therapyIntro: 'Therapeutic yoga works holistically on joints, muscles and nervous system to address the pain and underlying factors:',
      therapy: [
        { title: "Improves Flexibility and Range of Motion", description: "Gentle stretching and asanas increase flexibility in muscles and connective tissues, counteracting stiffness and improving range of motion." },
        { title: "Strengthen Muscles and Joints", description: "Specific practices like shakti vikashak asanas strengthen the muscles that support joints, providing stability and reducing strain on weakened areas." },
        { title: "Enhances Joint Health", description: "Controlled movements and weight-bearing poses improve joint lubrication, reduce stiffness and slow down the progression of degenerative conditions like arthritis." },
        { title: "Corrects Postural Imbalances", description: "Yoga emphasizes proper alignment and body awareness, helping to identify and correct poor posture, especially back and neck pain." },
        { title: "Reduces Inflammation", description: "Certain practices and stress-reducing effects of yoga, help lower inflammation in the body, which is a key factor for conditions like arthritis." },
        { title: "Promotes Relaxation and modulate pain", description: "Chronic pain can disrupt sleep, which in turn worsens pain. Yogic relaxation and improved sleep support the body’s natural healing processes and modulate pain perception." },
        { title: "Reduces Muscle Tension", description: "Muscoskeletal pain is often exacerbated by stress and muscle tension. Yoga’s stress-reducing effects help alleviate this tension and break the pain-tension cycle." },
        { title: "Improve Body Awareness (Proprioception)", description: "Yoga cultivates a greater awareness of the body’s position in space, improving balance, coordination and reduces chances of falls." }
      ],
      conditions: [
        { title: 'Chronic BackPain', 
          description: 'Chronic back pain is persistent discomfort or soreness in the back that lasts for months, and may linger even after the underlying cause has been treated. Contributing factors can include muscle strains or sprains, disc problems, arthritis, poor posture, and stress. The person may feel a dull ache, shooting pain, stiffness, and limited range of motion in the back. Yoga therapy is highly effective in managing chronic back pain by strengthening core muscles, improving flexibility and posture, reducing muscle tension, and promoting relaxation, which can alleviate pain and improve overall function.', 
          researchLinks: [] 
        },
        { title: 'Joint Pain', 
          description: 'Characterized by discomfort, aching, or soreness in any of the joints, such as knees, shoulders, hips, elbows, or wrists, joint pain can arise from various factors.These might include injury, overuse, different forms of arthritis, infections, or autoimmune conditions.People experiencing joint pain may notice sensations ranging from mild to severe, along with stiffness, swelling, redness, and a reduced ability to move the affected joint.Yoga therapy can be a valuable tool in managing this issue by enhancing flexibility and strength around the joints, decreasing stiffness, improving circulation, and encouraging mindful movement, ultimately helping to ease discomfort and support joint health.', 
          researchLinks: [] 
        },
        { title: 'Arthritis', 
          description: 'A common condition, arthritis involves inflammation of one or more joints, resulting in pain, stiffness, and swelling. Its development can be influenced by factors like genetics, age, prior injuries, obesity, and autoimmune disorders. Those with arthritis often report joint pain, morning stiffness, swelling, redness, and a progressive decrease in their range of motion. Yoga therapy can provide significant support by gently improving joint mobility and flexibility, strengthening the muscles that support the joints, alleviating pain and inflammation through specific poses and breathing practices, and fostering overall well-being.', 
          researchLinks: [] 
        },
      ],
    },
    {
      title: 'Hormonal Imbalance',
      summary: 'Hormones are chemical messengers produced by endocrine glands that travel through the bloodstream to tissues and organs. They play a crucial role in regulating many bodily functions, including metabolism, growth and development, reproduction, mood, and sleep. A hormonal imbalance occurs when there is too much or too little of one or more hormones in the bloodstream and can be caused by disorders of the endocrine glands, Stress, Autoimmune conditions, Genetic factors, poor lifestyle, etc. Even small imbalances can lead to significant health issues.',
      therapyIntro: 'Yoga therapy work simultaneously on the physiological and mental state to address the root cause of hormonal imbalances:',
      therapy: [
        { title: "Stimulate Endocrine Glands", description: "Specific asanas directly or indirectly stimulate and massage the endocrine glands, helping restore their balance." },
        { title: "Enhance Neuroendocrine Feedback loops", description: "Yoga helps to modulate the HPA axis and improve the communication within the neuroendocrine system. This ensures hormone levels are properly monitored and regulated." },
        { title: "Balance the Nervous System", description: "Yoga shifts the body from sympathetic (fight-or-flight) to parasympathetic (rest-and-digest) state, which is crucial for hormone regulation and stress reduction." },
        { title: "Improve Mood and Reduces Anxiety", description: "Promotes emotional well-being by alleviating symptoms of anxiety and depression, which positively influence hormone balance." },
        { title: "Promote Detoxification", description: "Yoga practices like twists, inversions and cleansing techniques (kriyas) help with the elimination of excess hormones and metabolic byproducts." },
        { title: "Improve Blood Circulation", description: "Increased blood flow to the endocrine glands like thyroid, adrenal glands etc., helps improve their functioning." },
        { title: "Enhance Sleep quality", description: "Yoga promotes relaxation and regulate the body’s circadian rhythm, which is essential for healthy hormone production and regulation." }
      ],
      conditions: [
        { title: 'Thyroid Disorder', 
          description: 'Thyroid imbalance refers to conditions where the thyroid gland, responsible for producing crucial metabolic hormones, is either overactive (hyperthyroidism) or underactive (hypothyroidism). Contributing factors can include autoimmune diseases, iodine deficiency, genetics, and certain medications. Common symptoms vary depending on the type but can include fatigue, weight changes, mood swings, hair loss, and changes in heart rate. Yoga therapy helps by reducing stress, improving circulation, balancing energy levels, and stimulating the thyroid gland through specific techniques.', 
          researchLinks: [] 
        },
        { title: 'PCOS/PCOD', 
          description: 'Polycystic Ovary Syndrome (PCOS), also sometimes referred to as Polycystic Ovarian Disorder (PCOD), is a hormonal disorder common among women of reproductive age characterized by infrequent or prolonged menstrual periods and often high levels of androgens (male hormones). Factors like genetic predisposition, insulin resistance, and inflammation may contribute to this. Common symptoms include irregular periods, excess hair growth, acne, weight gain, and the presence of multiple cysts on the ovaries. Yoga therapy is helps by reducing stress and anxiety, improving insulin sensitivity, promoting hormonal balance through specific postures, and aiding in weight management.', 
          researchLinks: [] 
        },
        { title: 'Menopause', 
          description: 'Menopause is a natural biological process, typically occurring in late 40s or early 50s, when the ovaries stop producing estrogen and progesterone. Common symptoms include hot flashes, night sweats, sleep disturbances, mood changes, and bone density loss. Yoga therapy can offer significant relief from menopausal symptoms by calming the nervous system, reducing stress, improving sleep quality, balancing energy levels, and promoting overall well-being through a combination of asanas, pranayama, and relaxation techniques.', 
          researchLinks: [] 
        },
      ],
    },
    {
      title: 'Respiratory Conditions',
      summary: 'Respiratory conditions are a group of illnesses affecting the airways that lead to breathing difficulties. These conditions often arise from inflammation, obstruction, or damage to the respiratory system caused by factors such as long-term exposure to irritants, infections, allergic reactions, or genetic predispositions. Ultimately, they impair the body\'s ability to breathe effectively, impacting oxygen levels and resulting in symptoms like wheezing, coughing, chest tightness, and nasal congestion.',
      therapyIntro: 'Yoga therapy provides a holistic framework to address the conditions with integrated breathing, relaxation and physical movement:',
      therapy: [
        { title: "Improves Breathing Mechanics", description: "Specific yoga and pranayama techniques strengthen the respiratory muscles, improve lung capacity and promote efficient and deeper breathing." },
        { title: "Reduces Airway Inflammation", description: "Breathing exercises and meditation help regulate the nervous system, contributing to lowering inflammation in the airways, particularly beneficial for asthma and bronchitis." },
        { title: "Mucus Clearance", description: "Specific yoga practices and kriyas like Kapalabhati, Jalneti help with clearing the nasal passages, airways and sinuses, helping with easier breathing." },
        { title: "Improves Airflow", description: "Yoga postures help open up the chest and rib cage, allowing for greater lung expansion and potentially improving airflow." },
        { title: "Reduces Stress and Anxiety", description: "Yoga’s calming effects on the nervous system help reduce stress and anxiety which may be a contributing factor for certain conditions and improve overall well-being." },
        { title: "Increases Exercise tolerance", description: "While physical activity can be challenging with respiratory conditions, gentle yoga movements help improve strength, flexibility and endurance, gradually increasing tolerance." }
      ],
      conditions: [
        { title: 'Asthma', 
          description: 'Asthma is a chronic respiratory condition in which the airways become inflamed and narrowed, making it difficult to breathe. Various triggers can exacerbate asthma, including allergens, irritants like smoke and pollution, exercise, cold air, and stress. Common symptoms include wheezing, coughing, shortness of breath, and chest tightness. Yoga therapy supports individuals with asthma by teaching breathing techniques to improve lung capacity and control, reducing stress and anxiety which can trigger attacks, and strengthening respiratory muscles through specific postures.', 
          researchLinks: [] 
        },
        { title: 'Chronic Sinusitis', 
          description: 'Chronic Sinusitis involves prolonged inflammation of the sinus cavities, lasting three months or more. This can be triggered by persistent infections, allergies, nasal polyps or deviated septum, structural issues, or immune problems. Common symptoms include ongoing nasal congestion, facial pressure or pain, headaches, reduced sense of smell, and thick nasal discharge. Yoga therapy can help manage chronic sinusitis by improving sinus drainage through specific movements, reducing inflammation with calming breathing, and easing stress that can aggravate the condition.', 
          researchLinks: [] 
        },
        { title: 'Bronchitis/COPD', 
          description: 'Bronchitis involves inflammation of the bronchial tubes, which carry air to the lungs. Chronic Obstructive Pulmonary Disease (COPD) is a progressive lung disease that includes emphysema and chronic bronchitis, making it hard to breathe. Contributing factors for both include smoking, exposure to air pollutants, and respiratory infections. Symptoms often include persistent coughing, production of mucus, shortness of breath, and wheezing. Yoga therapy can benefit individuals with bronchitis and COPD by teaching breathing exercises to improve lung function and oxygenation, strengthening respiratory muscles, reducing anxiety associated with breathlessness, and enhancing overall quality of life. ', 
          researchLinks: [] 
        },
      ],
    },
  ];

  return (
    <Box id="page" backgroundColor="brand.200" alignItems={'center'}>

      <Box id="footer" bgColor="brand.300" display="flex">
        <Header />
      </Box>

      {categories.map((category) => (
        <Box key={category.title} m={4} my={8} p={4} borderWidth="1px" borderRadius="md" width={'90%'} mx='auto' bgColor={'brand.100'}>
          <Heading as="h3" m={2} mb={4} fontSize={'2xl'}>
            {category.title}
          </Heading>
          <Text p={2}> {category.summary} </Text>
          <Heading as="h6" m={2} fontSize={'lg'}> Role of Yoga Therapy </Heading>
          <Text p={2}> {category.therapyIntro} </Text>
          {category.therapy.map((benefit) => (
            <UnorderedList>
              <ListItem ml={4}>                 
                <Text p={1}>
                  <Text as="span" fontWeight="semibold">
                    {benefit.title}
                  </Text>
                  {': '} {benefit.description}
                </Text>
              </ListItem>
            </UnorderedList>
          ))}
          {isMobile ? (
            <Accordion allowToggle mt={6}>
              {category.conditions.map((condition) => (
                <AccordionItem key={condition.title}>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Text fontSize={'1.1em'} fontWeight={'semibold'}> {condition.title} </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Box mb={4}>
                      <Text mb={2}> {condition.description} </Text>
                      {condition.researchLinks.slice(0,2).map((paper) => (
                        <Card key={paper.title} mb={4} p={4} boxShadow="md">
                          <VStack align="start" spacing={2}>
                            <Text fontWeight={'500'} color={'blue.600'}>
                              <Link href={paper.link} isExternal> {paper.title} </Link>
                            </Text>
                            <Text color='gray.600' fontSize={'0.9em'}>{paper.description}</Text>
                          </VStack>
                        </Card>
                      ))}                    
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          ) : (
            <Tabs isFitted={true} variant="enclosed-colored" m={4} my={8}>
              <TabList>
                {category.conditions.map((condition) => (
                  <Tab key={condition.title}>
                    <Text fontSize={'1.1em'} fontWeight='semibold'>{condition.title}</Text>
                  </Tab>
                ))}
              </TabList>
              <TabPanels>
                {category.conditions.map((condition) => (
                  <TabPanel key={condition.title}>
                    <Box mb={4}>
                      <Text mb={6}> {condition.description} </Text>
                      {condition.researchLinks.map((paper) => (
                        <Card key={paper.title} mb={4} p={4} boxShadow="md">
                          <VStack align="start" spacing={2}>
                            <Text fontWeight={'500'} color={'blue.600'}>
                              <Link href={paper.link} isExternal> {paper.title} 
                              </Link>
                              <Icon ml={2} as={FaExternalLinkAlt} boxSize={3} color="blue.600" />
                            </Text>
                            <Text color='gray.600' fontSize={'0.9em'}>{paper.description}</Text>
                          </VStack>
                        </Card>
                      ))}                    
                    </Box>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          )}
        </Box>
      ))}
      <Box id="footer" bgColor="brand.200" display="flex">
        <Footer />
      </Box>
    </Box>
  );
};

export default Research;
