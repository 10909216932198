import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  fonts: {
    heading: `'Montserrat', sans-serif`,
    body: `'Open Sans', sans-serif`,
  },

  colors: {
    brand: {
      100: '#ebfcff',
      200: '#dbf6fa',
      300: '#0e4f62'
    }
  },

  breakpoints: {
    base: '0em', // 0px
    sm: '30em', // ~480px
    md: '48em', // ~768px
    lg: '62em', // ~992px
    xl: '80em', // ~1280px
    xxl: '96em', // ~1536px
  }
});

export default customTheme;