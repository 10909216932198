import { useSearchParams } from 'react-router-dom';

export const useSignupUrl = () => {
  const [searchParams] = useSearchParams();

  const getSignupUrlWithRef = () => {
    const refCode = searchParams.get('ref') || null;

    const baseUrl = 'https://app.arogic.com';
    const url = new URL(baseUrl);
    const localref = localStorage.getItem('ref');

    if (refCode) {
      url.searchParams.set('ref', refCode);
    } else if (localref) {
      url.searchParams.set('ref', localref);
    }
    return url.toString();
  }
  return getSignupUrlWithRef;
};