import { Box, Heading, Wrap, Text, VStack, Circle, HStack, } from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';

import SignupIcon from '../assets/icons/signup.svg';
import ConsultIcon from '../assets/icons/consult.svg';
import ScheduleIcon from '../assets/icons/schedule.svg';
import ApprovedIcon from '../assets/icons/approved.svg';
import SupportIcon from '../assets/icons/support.svg';
import { useBreakpointValue } from '@chakra-ui/react';
import USPCarousel from './USPCarousel';

const steps = [
  { icon: SignupIcon, description: 'Get Started by answering few simple questions' },
  { icon: ConsultIcon, description: 'Meet Your Therapist to discuss your needs' },
  { icon: ScheduleIcon, description: 'Get a Personalized Therapy Plan tailored for you' },
  { icon: ApprovedIcon, description: 'Subscribe to begin your healing journey' },
  { icon: SupportIcon, description: 'Enhance your wellbeing with continuous support' },
];

const mobileSteps = [
  { icon: SignupIcon, title: 'Get Started', description: 'by answering few simple questions' },
  { icon: ConsultIcon, title: 'Meet your Therapist', description: 'to discuss your needs' },
  { icon: ScheduleIcon, title: 'Get a Therapy Plan', description: 'tailored to your requirements' },
  { icon: ApprovedIcon, title: 'Subscribe', description: 'to begin your healing journey' },
  { icon: SupportIcon, title: 'Enhance Wellbeing', description: 'with continuous support' },
];

const HowItWorks = () => {
  const width = useBreakpointValue({ base: '2.5em', md: '4em' });
  const height = useBreakpointValue({ base: '2.5em', md: '4em' });
  const isMobile = useBreakpointValue({ base: true, sm: false });

  const containerStyle = {
    padding: { base: 2, xl: 4 },
    margin: { base: 3, xl: 4 },
    display: "flex",
    width: '100%',
    alignItems: "center",
  };

  const textStyle = {
    padding: { base: 1, lg:2, xxl: 3 },
    marginTop: { base: 1, lg: 2 },
    textAlign: "center",
    fontSize: '1em',
  };

  return (
    <Box sx={containerStyle}>
      <VStack alignItems="center" mb="4" mt="4" p="2" justifyContent="center" >
        
        <Heading as='h3' size='lg' textAlign={'center'} mb={4}> How it Works</Heading>
        {!isMobile &&
          <Wrap spacing={{ base: 0, lg: 1, xxl: 2 }} justify='center' mt={4}>
            {steps.map((step, index) => (
              <VStack key={index} align="center" mb={4} minWidth={"120px"} maxWidth={"19%"}>
                <ReactSVG src={step.icon} style={{ width, height }} />
                <Text sx={textStyle}> {index+1}. {step.description}</Text>
              </VStack>
            ))}
          </Wrap>
        } 
        {isMobile && 
          <VStack spacing={1} align="left">
            {mobileSteps.map((step, index) => (
              <HStack key={index} align="left" width="100%">
                <Circle size="40px" bg="teal.500" color="white" p={1} ml={1}>
                  {index+1}
                </Circle>
                < VStack alignItems={'left'} mb={4} p={0}>
                  <Text textAlign={'left'} fontSize={'1em'} fontWeight={700} m={-1} p={0} ml={2}> {step.title}</Text>
                  <Text textAlign={'left'} fontSize={'1em'} m={-1} p={0} ml={2}> {step.description}</Text>
                </VStack>
              </HStack>
            ))}
          </VStack>
        }
        <Text textAlign={'center'} fontSize={'1.1em'} fontWeight={600} color={'teal.800'} m={2} mt={6}>
          Our programs are designed to maximize the impact and value with innovation and technology        </Text>
        <USPCarousel />
      </VStack>
    </Box>
  );
};

export default HowItWorks;