import Carousel from "react-multi-carousel";
import { Box, Card, CardBody, Link, Text } from '@chakra-ui/react';
import "react-multi-carousel/lib/styles.css"; // Import the CSS
import { useState } from "react";

function FeedbackCard({ item, index }) {
  const MAX_LENGTH = 310;
  const [showFullFeedback, setShowFullFeedback] = useState(false);

  const toggleFeedback = () => {
    setShowFullFeedback(!showFullFeedback);
  };

  const truncateWords = (text) => {
    if (text.length <= MAX_LENGTH) {
      return text;
    }
    const truncated = text.substring(0, MAX_LENGTH);
    const lastSpaceIndex = truncated.lastIndexOf(' ');
    if (lastSpaceIndex === -1) {
      return truncated + ' ...';
    }
    return truncated.substring(0, lastSpaceIndex) + ' ...';
  }

  let feedbackToShow = showFullFeedback
    ? item.feedback
    : truncateWords(item.feedback);

  return (
    <Box margin={{ base: 1, xl: 2, xxl: 3 }} p={1} pb={4}>
      <Card key={index} width="100%" variant={'elevated'} align={'left'} shadow={'md'} rounded={'lg'}>
        <CardBody width={'100%'} minH={{base:'24em', lg:'20em', xxl:'16em'}}>
          <Text textAlign={'left'} fontSize="1.1em" fontWeight={700} mb={2}>
            {item.name}
          </Text>
          <Text textAlign={'left'} fontWeight={600} mb={2}>
            {item.info}
          </Text>
          <Text textAlign={'left'} m={1} whiteSpace="pre-line">
            {feedbackToShow}
          </Text>
          {item.feedback.length > MAX_LENGTH && 
          <Link marginLeft={1} onClick={toggleFeedback} color="blue.500" fontWeight="semibold" textDecoration={'none'}>
            {showFullFeedback ? 'Show less' : 'Show more'}
          </Link>}
        </CardBody>
      </Card>
    </Box>
  );
}

const FeedbackCarousel = () => {

  const feedbacks = [
    {
      name: 'Archana More', info: '58 years old, Thane', 
      feedback: 'A spine fracture (from an accident) and covid, curtailed all my movements and resulted in severe muscle spasms. This was the time, I came across Yoga and Umesh Sir. It has changed my life to painless day to day activities, full day energy and above all I got my confidence back. The 1x1 sessions are always effective, but even the video sessions are like 1x1. Group sessions on Saturday is also very helpful. Thank u so much Arogic n Umesh Sir 🙏🏻' 
    },
    {
      name: 'Shreebala Neema', info: '62 years old, Indore',
      feedback: 'I joined Arogic since last 5 months. It is very helpful therapy to live healthier and disease free life, not only for physical but also mental and spiritual health. Physically by exercises, asanas, mentally by different pranayamas and spiritually by mantra chanting and focus on inner self. I feel relief from high BP and swelling in ankles. My mental concentration has also increased. I am thankful to yoga sir Umesh Lalit to treat us according to our body condition, step by step in a very friendly and easy way.'
    },
    {
      name: 'Vijay Kadam', info: '57 years old, Maharastra',
      feedback: 'I registered with Arogic in the month of November 2024 and have experienced excellent improvement in my physical, mental and psychic health. I am overwhelmed by positive changes that have occurred in my daily routine, physical health and mental peace. I am thankful to entire team of Arogic and Mentors.'
    },
    {
      name: 'Mugdha T', info: '22 years old, Mumbai',
      feedback: 'The therapy sessions are really helpful and relaxing. I\'ve been seeing positive changes both mentally and physically. The video sessions are very informative and comfortable to follow along and feel almost similar to one on one sessions.Also the feature of recheduling video sessions as per one\'s convenience has been very helpful. Thank you!'
    },


  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1537 },
      items: 3, // Allow overriding slidesToSlide
      partialVisibilityGutter: 0
    },
    laptop: {
      breakpoint: { max: 1536, min: 1280 },
      items: 3,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1279, min: 720 },
      items: 2,
      partialVisibilityGutter: 10
    },
    mobile: {
      breakpoint: { max: 719, min: 0 },
      items: 1,
      partialVisibilityGutter: 30
    },
  };

  return (
    <Box width='92vw'>
      <Carousel
        swipeable={true}
        draggable={true}
        partialVisible={true}
        arrows={false}
        showDots={true}
        responsive={responsive}
        ssr={false} // means to render carousel on server-side.
        infinite={true}
        keyBoardControl={true}
        autoPlay={true}
        autoPlaySpeed={10000}
        customTransition="transform 1s ease-in-out"
        transitionDuration={1000}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item"
      >
        {feedbacks.map((item, index) => (
          <FeedbackCard key={index} item={item} index={index} />
        ))}
      </Carousel>
    </Box>
  );
};

export default FeedbackCarousel;
