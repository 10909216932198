import Carousel from "react-multi-carousel";
import { Box, Card, CardBody, Text } from '@chakra-ui/react';
import "react-multi-carousel/lib/styles.css"; // Import the CSS
import { useBreakpointValue } from '@chakra-ui/react';

const USPCarousel = () => {
  const usp = [
    { title: 'Certified Therapists',      description: 'With deep understanding of human physiology and therapeutic application of yoga practices.' },
    { title: 'Unique Program Structure',  description: 'Combines benefits of one-on-one consultations, flexible video and informative group sessions.' },
    { title: 'Tailored Video Sessions',   description: 'Leverage our proprietary technology to personalize each session for your body and condition.' },
    { title: 'Scientific Techniques',     description: 'Remove toxins, regulate energy flow, balance hormones, reduce stress and strengthen the body.' },
  ];

  const isDesktop = useBreakpointValue({ base: false, xxl: true });


  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1537 },
      items: 4, // Allow overriding slidesToSlide
      partialVisibilityGutter: 0
    },
    laptop: {
      breakpoint: { max: 1536, min: 992 },
      items: 3,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 991, min: 480 },
      items: 2,
      partialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 479, min: 0 },
      items: 1,
      partialVisibilityGutter: 0
    },
  };

  return (
    <Box width='87vw'>
      <Carousel
        swipeable={true}
        draggable={true}
        partialVisible={false}
        arrows={false}
        showDots={isDesktop?false:true}
        responsive={responsive}
        ssr={false} // means to render carousel on server-side.
        infinite={true}
        keyBoardControl={true}
        autoPlay={isDesktop? false : true}
        autoPlaySpeed={5000}
        customTransition="transform 1s ease-in-out"
        transitionDuration={1000}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item"
      >
        {usp.map((item, index) => (
          <Box margin={{ base: 1, xl: 2, xxl: 3 }} p={1} pb={4} key={'usp_'+index} >
          <Card width="100%" variant={'elevated'} align={'center'} shadow={'md'} >
            <CardBody width={'100%'}>
              <Text textAlign={'left'} fontSize={{base:'1em', lg:'1.1em'}} fontWeight={700} mb={2}> {item.title} </Text>
              <Text textAlign={'left'} fontSize={{base:'0.9em', lg:'1em'}} noOfLines={3} m={1}> {item.description} </Text>
            </CardBody>
          </Card>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default USPCarousel;
