import { Box, Heading, VStack, Text, Link, Button } from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/react';
import { useSignupUrl } from './UseSignupUrl';
import { logEvent } from '../analytics';

const HeroContent = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const getSignupUrl = useSignupUrl();
  const signupUrl = getSignupUrl();

  const handleSignUpClick = () => {
    logEvent('Button Click', 'Sign Up Now', 'Sign up now - Hero');
  };

  return (
    <>
      <Box width = "100%" justifyContent="center" alignItems="center" m="2" mb="4" p="6">
        <VStack align="center" spacing={{ base: 1, lg: 2, xxl: 4 }} padding={{ base: 0, lg: 1, xxl: 2 }}>
        <Heading as='h1' color={'white'} textAlign={'center'} fontSize={{ base: "2.5em", md: "2.8em", xl: "3em", xxl:"3.5em" }}>
          Personalized Yoga Therapy
        </Heading>
        <Heading as='h5' fontWeight="600" color={'white'} textAlign={'center'} fontSize={{ base: "1m", md: "1.1em", xl: "1.2em"}}>
          Delivered online, at your convenience
        </Heading>
        <br/>
        <br/>
        {isMobile &&
          <div>
            <Text fontWeight="600" color={'white'} textAlign={'center'} fontSize={{ md: "1.1em", xl: "1.2em" }}>
              Get long-lasting relief from chronic conditions by addressing the underlying root cause
            </Text>
          </div>
        }
        {!isMobile && 
        <div>
          <Text fontWeight="600" color={'white'} textAlign={'center'} fontSize={{ md: "1.1em", xl: "1.2em" }}>
              Need long-lasting relief from your chronic condition?
          </Text>
          <Text color={'white'} textAlign={'center'} fontSize={{ md: "1.1em", xl: "1.2em" }}>
              Experience the power of Personalized Yoga Therapy to holistically address the underlying root cause.
          </Text>
        </div>
        }
        <br />
        <br />
        <Link href={signupUrl} width="100%" textAlign={'center'}>
          <Button size='lg' mb={4} onClick={handleSignUpClick}>
            Sign up now
          </Button>
        </Link>
        </VStack>
      </Box>
    </>
  )
};

export default HeroContent;

