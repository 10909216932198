import { Box, SimpleGrid, Image, Text, Card, CardBody, Heading, VStack } from '@chakra-ui/react';
import Relief from '../assets/graphics/relief.svg';
import Energy from '../assets/graphics/energy.svg';
import Flexible from '../assets/graphics/flexibility.png';
import Calm from '../assets/graphics/peace.svg';
import Balance from '../assets/graphics/balance.png';
import Quality from '../assets/graphics/quality.svg';
import Heart from '../assets/graphics/BPControl.png';
import Digestion from '../assets/graphics/metabolism.svg';

import { useBreakpointValue } from '@chakra-ui/react';


const Benefits = () => {

  const isMobile = useBreakpointValue({ base: true, lg: false });
  
  const benefits = [
    { image: Relief, title: 'Relief from pain and discomfort' },
    { image: Flexible, title: 'Increased strength and flexibility' },
    { image: Digestion, title: 'Improved digestion and metabolism' },
    { image: Heart, title: 'Balanced breathing and blood flow' },
    { image: Calm, title: 'Reduced stress and anxiety' },
    { image: Balance, title: 'Positive mood and emotional stability' },
    { image: Energy, title: 'Consistent day-long energy' },
    { image: Quality, title: 'Enhanced control and quality of life' },
  ];

  const textStyle = {
    margin: { base: -2, lg: 0, xl: 1 },
    marginTop: { base: 3, lg: 4, xl:4},
    textAlign: "center",
    fontSize: {base: '0.9em', md:'1.1em'},
    fontWeight: '600',
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" padding="4" width={{ sm:"100%", md:"90%"}} mx="auto">
      <VStack alignItems="center" mt="4" mb="8">
        <Heading as='h3' size='lg' textAlign={'center'} mb={4}> Benefits You Gain</Heading>
        <SimpleGrid columns={{ base: 2, sm: 3, lg:4, xxl:4 }} spacingX={{base: 4, md:8, lg: 10, xxl:16}} spacingY={2}>
          {benefits.slice(0, isMobile ? 6 : 8).map((benefit, index) => (
              <Card key={index} variant="none" w={{ base: "10em", md: '12em', lg: '16em', xxl: '16em' }}>
              <CardBody backgroundColor={'#ebfcff'}>
                <Box display="flex" flexDirection='column' textAlign="center" alignItems="center" justifyContent={'center'}>
                  <Image src={benefit.image} alt={benefit.title} borderRadius="md" mb={1}
                    w={{ base: "8em", lg: '10em' }} />
                  <Text sx={textStyle}> {benefit.title} </Text>
                </Box>
              </CardBody>
            </Card>
          ))}
        </SimpleGrid>
      </VStack>
    </Box>
  );
};

export default Benefits;

