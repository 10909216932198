import { useLocation, Link } from 'react-router-dom';
import { useEffect } from 'react';

const RefLink = ({ to, ...rest }) => {
  
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getNewTo = () => {
    let newTo;
    if (typeof to === 'string') {
      newTo = { pathname: to, search: location.search };
    } else {
      newTo = { ...to, search: location.search };
    }
    return newTo;
  };

  return <Link to={getNewTo()} {...rest} />;
};

export default RefLink;