import { Box, VStack } from '@chakra-ui/react';
import LoginBackground from '../assets/LoginBackground.jpg';
import Footer from '../components/Footer.js';
import HeroContent from '../components/HeroContent.js';
import Header from '../components/Header.js';
import Approach from '../components/Approach.js';
import Conditions from '../components/Conditions.js';
import CTA from '../components/Cta.js';
import HowItWorks from '../components/HowItWorks.js';
import Benefits from '../components/Benefits.js';
import TherapistProfile from '../components/TherapistProfile.js';
import Feedback from '../components/Feedback.js';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';


const HomePage = () => {
  const [searchParams] = useSearchParams();
  const referredBy = searchParams.get('ref') || null;

  useEffect(() => {
    try {
      if (referredBy) {
        localStorage.setItem('ref', referredBy);
      }
    } catch (error) {
      console.error('Error storing referredBy in localStorage:', error);
    }
  }, [referredBy]);

  return (
    <>
      <Box id="page" backgroundColor="#dbf6fa" height="100%" width="100%">
          <Box id="hero" backgroundColor='#0c5263' backgroundImage={LoginBackground} backgroundSize="cover" backgroundPosition="center" display="flex" width="100%"> 
          <VStack width="100%">
            <Header colorstyle='#ebfcff'/>
              <HeroContent />
            </VStack>
          </Box>
        <Box id="conditions" display="flex" width={"100%"} bgColor={'#dbf6fa'}>
          <Conditions />
        </Box>
                
        <Box id="approach" display="flex" width={"100%"} bgColor={'#ebfcff'}> 
          <Approach/>
        </Box>

        <Box id="howitworks" display="flex" width={"100%"} bgColor={'#dbf6fa'}>
          <HowItWorks />        
        </Box>

        <Box id="benefits" display="flex" width={"100%"} bgColor={'#ebfcff'}>
          <Benefits />
        </Box>
        <Box id="benefits" display="flex" width={"100%"} bgColor={'#dbf6fa'}>
          <TherapistProfile />
        </Box>
        <Box id="feedback" display="flex" width={"100%"} bgColor={'#ebfcff'}>
          <Feedback />
        </Box>
        <Box id="cta" display="flex" width={"100%"} bgColor={'#dbf6fa'} >
          <CTA />
        </Box>        
        <Box id="footer" bgColor="#dbf6fa" display="flex"> 
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default HomePage;
